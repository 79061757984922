import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from "../components/layout";
import ResumePdf from '../../static/2024-Fullerton-CV.pdf';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h3>{`CV`}</h3>
    <a href={ResumePdf}>Click</a> to download PDF.
    <br />
    <br />
    <iframe frameBorder="0" scrolling="yes" width="100%" height="800px" src={ResumePdf} />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      